import React from 'react'
import Link from 'gatsby-link'
import './style.css'
import { Card, Icon, Divider, Table, Label, Header } from 'semantic-ui-react'

class CrossImpact extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
        // isActive: 1
    }
  }

    componentDidMount() {
        console.log(this.props)
    }



    render () {
        // console.log(this.state.isActive, this.props.hit.fields.number)

        return (
            <div className="ci-table">
                <Table definition celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell>New disposal methods</Table.HeaderCell>
                            <Table.HeaderCell className="teal">Less religious identity</Table.HeaderCell>
                            <Table.HeaderCell>Digital afterlife</Table.HeaderCell>
                            <Table.HeaderCell>Laws & regulations</Table.HeaderCell>
                            <Table.HeaderCell>Going green</Table.HeaderCell>
                            <Table.HeaderCell>Funeral costs</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>New disposal methods</Table.Cell>
                            <Table.Cell />
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>--</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Less religious identity</Table.Cell>
                            <Table.Cell>+</Table.Cell>
                            <Table.Cell/>
                            <Table.Cell>+</Table.Cell>
                            <Table.Cell>--</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>+</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Digital afterlife</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>++</Table.Cell>
                            <Table.Cell />
                            <Table.Cell>++</Table.Cell>
                            <Table.Cell>+</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Laws & regulations</Table.Cell>
                            <Table.Cell>--</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>--</Table.Cell>
                            <Table.Cell/>
                            <Table.Cell>-</Table.Cell>
                            <Table.Cell>-</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Going Green</Table.Cell>
                            <Table.Cell>+</Table.Cell>
                            <Table.Cell>+</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>+</Table.Cell>
                            <Table.Cell />
                            <Table.Cell>-</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Funeral Costs</Table.Cell>
                            <Table.Cell>+</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>=</Table.Cell>
                            <Table.Cell>+</Table.Cell>
                            <Table.Cell>+</Table.Cell>
                            <Table.Cell />
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        )
    }
}



export default CrossImpact
