import React from 'react'
import Link from 'gatsby-link'
import { navigateTo } from 'gatsby-link'
import ScanningContainer from '../../../components/ScanningContainer'
import CrossImpact from '../../../components/CrossImpact'
import Drivers from '../../../components/Drivers'
import { Header, Icon, List, Divider, Item, Dimmer, Loader } from 'semantic-ui-react'
import bgVid1 from '../../../assets/dark_slime_vid.mp4'
import './style.css'
import brainSkeleton from '../../../assets/brain-skeleton.png'


class CollapsePage extends React.Component {
constructor(props) {
  super(props);
  // Don't call this.setState() here!
  this.state = {
      drivers: [],
      dimmer: true
  }
  this.onNextFrame = this.onNextFrame.bind(this);
  this.removeDimmer = this.removeDimmer.bind(this);
}


    componentDidMount() {
        this.onNextFrame(this.removeDimmer)

    }

    removeDimmer() {
        this.setState({dimmer: false})
    }

    onNextFrame(callback) {
    setTimeout(function () {
        window.requestAnimationFrame(callback)
    }, 0)
}

    render () {
        // const allHits = this.state.allHits;
        return (
            <div className="article-page collapse-scenario">
             {/* <video playsInline autoPlay muted loop poster="" className="bgvid">
                    <source src={bgVid1} type="video/mp4" />
                </video> */}
            <div className="collapse-container section-content">
            <div className="article-back">
                 <Link 
                    className="" 
                    to='/scenarios'
                  
                    >
                    &larr;
                </Link> 
            </div>

            <div className="collapse-panel collapse-panel-title">
                <div className="collapse-title-wrapper">
                    <h1>The Second Circle</h1>
                    <div className="collapse-byline">by Tom Rau</div>
                </div>
                {/* <p>Do you want to talk about a grind? I bet you do... </p> */}
                <div className="brain-skel">
                    <img src={brainSkeleton}/>
                </div>
                {/* <div className="circle-container">
                    <div className="outer-circle"><div className="inner-circle"></div></div>
                </div> */}
            </div>

            <div className="collapse-content">
            <p><span className="first-character">D</span>o you want to talk about a grind? I bet you do. I’m sure you think the world you are living in is pretty messed up, but just wait. Evolutionarily speaking, your world is the Model T and my world is a pack of single-minded autonomous cars bearing down on a crowd of helpless happy people. That’s really just the tip of the iceberg, not that we have those anymore.</p>

            <p>Suffice it to say that the world that I live in is a shithole. Actually, I kind of need to rephrase that. The worlds, plural, that I am living in, are shitholes. The real world, the one in which you are alive in, albeit decades earlier, has become mostly uninhabitable. That shouldn’t be too hard to imagine, just make a checklist of all the systems that had begun to crack, climate change, income disparity, runaway technology, war, suicide, resource depletion, famine, disease — check them off, close your eyes, and let the darkness in. I bet you have a pretty good picture. The other world, the one in which I am talking to you from, it’s a whole other trip, and I assure you it’s an all-new kind of messed up.</p>

            <p>First let me serve you a little pu-pu platter of humanities recent woes. For starters, and I promise for most this would be the entree, we had a full-blown nuclear war. It involved China, Russia, Israel, Iran and the United States all launching nukes over the course of a six month period in 2032 that killed hundreds of millions of people and toppled the global economy. Needless to say, globalism isn’t doing so hot right now, and if you thought climate change was a hot topic then, well, the nuclear bombs definitely didn’t help. Temperatures on earth regularly swell above 135F. There isn’t a coastal city in the world that you have ever even heard of unless you count Nashville. And guess what? Cowboy boots with Hawaiian shirts is still not a good look. Much of what used to be the warmer parts of the world are fully uninhabitable by anything other than insects and cacti. Income disparity? It has gone from ridiculous to plaid, with the rich not only being the only ones who can afford proper environmental protection, but also being the only ones who can afford genetic modifications, nano-tech, and next gen bio-embeds. They are richer, stronger, smarter, and more connected. I won’t lie, being rich seems like super work if you can get it. Most humans who aren’t rich live in biodomes, and it’s not a good life. So yeah, you live in a world with haves and have nots. We live in a world where the rich have become demi-gods and the rest of us are just sucking up air. But that’s not why I’m here. I am not, as you may be guessing, part of the resistance. I’m here to tell you about death in the future. So, let me tell you first hand about what it’s like to be dead in the future.</p>

            <Divider />

            <p><span className="first-character">M</span>y body is long gone, dissolved in a pool of chemicals via alkaline hydrolysis, aka liquid cremation, the most environmentally friendly, and more importantly, cheapest way to legally dispose of a body. Options are severely limited these days, traditional cremation is illegal for environmental reasons (turns out burning nanotechnology and other embedded devices is not cool), which frankly is kind of a joke, given that the planet itself is a giant burning corpse.
            </p>

            <p>While my body is no longer, my brain is alive and well, submerged in a pool of extremely conductive, viscous liquid gel inside a massively large data, storage, bio-med facility owned by Nectome, the company that perfected, created, frankensteined this technology and new world where I am communicating with you from.</p>

            <p>It went something like this. In 2015, Nectome invented a new technology that people referred to as second wave cryogenics. The initial technology allowed them to freeze human brains while they were still alive, allowing them to accurately capture all of the brain synapse activity of a living brain. The procedure killed you in the process, but it gave them the ability to create a near perfect mind map, which they claimed would be the first step in humans successfully cheating death. The elderly and dying signed up to be on their waiting list in droves. By 2028, with the help of breakthroughs in artificial intelligence and quantum computing, they were no longer freezing the brains to the point of no return. Instead, they could keep the brain alive and in nearly frozen in stasis. Turns out, it still had consciousness. They followed this with the biggest game changer of them all, a post-life, “brain only” platform they christened, the Second Circle, which offered the rich and bodiless their first chance at life after death, something the marketing people at Nectome began referring to as Artificial Afterlife.
            </p>
            <p>
            Technology had just begun to turn the super-rich into gods in the late 2020s and early 30s, but even with new drugs and genetic modification the human body still didn’t stand to last more than about 125 years. No one with the resources to live in this now broken down world wanted to be contained in what was still quickly going to become a beat up sack of skin and bones.  Nectome’s Second Circle offered them an alternative. For a million dollars a year, they would administer the final death blow to the dying client’s body and then move the brain into one the their facilities where it would be kept in stasis and hooked up to the Second Circle. The client would then wake up a few days later inside of the wonderful and pixel perfect world of 2C, which is how we who live here generally refer to the Second Circle. The body was dead, but it was still, for the rich, the next giant leap in the quest to conquer the still yet to be defeated — Death.
            </p>
            <p>
            That was the beginning, a platform that offered an escape from dying to the ultra-rich and famous, MMO style. I know what you are thinking, it’s like that really cool old episode of Black Mirror, “San Junipero.” And to that, I’d say, yes, in theory it was just that. But unlike in that now famous episode, our world was in a free fall. Hence, once the gates of death were opened, chaos ensued and we all wanted in.
            </p>
            <Divider />
            <p>
            <span className="first-character">A</span>rriving in 2C is like waking up from a night that ended in an extensive blackout. You are groggy, exceptionally confused, and your head is just all kinds of wrong, but then instead of finding out you may have accidentally slept with Susan from the HR, you find out you are alone in some sort of bourgeois retreat and with exceptionally well-threaded linens. I really think one of my first thoughts was, “Whoa. I guess I really will sleep a lot when I’m dead.”
            </p>
            <p>
            Today is Tuesday, March 7th, 2040. I have roughly 650,000 credits inside 2C. In theory, this is a lot of credits. In the real world that would amount to $650,000, which isn’t too shabby for a 22-year-old. While I was brimming with credits there is also a massive downside. First, I no longer have a body in the real world. Second, the cost of living safely in a post-nuclear holocaust world with no ozone layer is really high, like a 250Sf environmentally prepared apartment in an inhabitable city costs about $250,000 a year. Most people, live in biodomes, which are really much more like quasi-self sustaining refugee camps. It is no wonder that everyone still alive is racing to get to the the Second Circle and/or just wants to kill themselves, which by the way, if unsuccessful, now carries with it the wonderful and mandatory punishment of two years duty in a privatized work/prison camp. We humans really did a bang-up job, we managed to kill everything but capitalism. My problems, however are of a less corporal variety, if at the end of the month, I don’t have 1 million credits in my 2C wallet, they pull the plug on me and my brain, which coincidentally wouldn’t be the first time for me. Now I’m on a quest to make 350,000 credits in the next three weeks so that I can survive one more year here in the Second Circle. My life has become an in-app purchase, except that I’m stuck in the fucking app and the only people that can keep me alive are the people here with more money than god. What do they want: more access, more power, more everything? It’s an all new world, with all the same shit.
            </p>
            <Divider />
            <p>
            I’m scavenging a mostly uninhabited beachy area in 2C’s western quadrant. I’m searching for X-tracers, a digital remnant that when consumed by our avatars briefly creates enough interference in our software that we can communicate with the real world without being traced. Of course, the A.I.s in our suit patch the hack up pretty quickly, but if you stockpile 5 or 6 X-tracers, you can get in a couple of hours of anonymous communication with anyone or anything in the real world. Rumor is that X-tracers are created and left here by rogue programmers at Nectome who are sympathetic to the fact that outside communication is strictly forbidden and highly punishable. Those caught are immediately exiled from 2C and thus served up a much more permanent and relatable kind of death. I still have a mother in the real world. She wants to come here, to be with us.  But this is no life, it’s just one more layer of abstraction. I want to talk her out of it, to let her go naturally into the dark. But it’s hard, when those brief moments are all either of us have in this universe. So here I am, walking on a fake beach searching for something with which I can buy more fake time.
            </p>
            <Divider />
            <p>
            <span className="first-character">I</span> was born in 2014. I was declared dead at 3:34 am on January 3rd, 2037. I was hit by a car that was part of a planned terrorist attack. The attack took place shortly after midnight on New Year Eve. At its core it involved the programmatic takeover of tens of thousands of autonomous vehicles that targeted large crowds of people who were out. More than 35,000 people were killed during this attack. I was one of the thousands who were left in critical condition. After a day, it was pretty clear I was never going to wake back up. The doctors told my mom she should let me go. But like so many others in the world she couldn’t bear to lose any more. After two more days of being brutally kept alive by today’s finest and most expensive in medical technologies, a Nectome patient success representative visited with my mom and talked her into using her life savings to send me to the Second Circle.
            </p>
            <p>
            I’m sure you have so many questions, and maybe I have the answers to some of them. But I suspect I have even more questions than you. This is what I know. I don’t really feel different, to the best of my knowledge I am still me. Much of my life is easier now. There is no user interface in my user experience, only the messages intertwined seamlessly with my stream of thought. I am fully connected. The only difference between talking with someone here and messaging with them is that talking creates sound waves.
            </p>
            <p>
            Time is where it all starts falls apart. Never has time been more of a human construct than it is here. In the outside world, time is a way to measure age. Here it is a way only to measure when the rent is due. I don’t age, I don’t need sleep, and my avatar is timeless. My brain could live for a million years, and yet every 365 days 1 million credits are due or it’s lights out. I died and somehow I am still a slave to time and money. Life is cruel, even beyond the flesh. It’s the same grind we always had, just this time the goalposts never stop moving. We did something impossible, we cheated death. But just like the real world, eventually we almost all get caught.
            </p>
            </div>
            </div>
            </div>
        )
    }
}


export default CollapsePage
