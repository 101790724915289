import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Link from 'gatsby-link'
import ScanningHit from '../ScanningHit'
import './drivers.css'
import { Header ,Icon, Divider } from 'semantic-ui-react'

class Drivers extends React.Component {
    state = {
        activeDriver:this.props.activeDriver,
        activeDriverNumber: this.props.activeDriverNumber || 1
    }

    renderCorrectContent(driver) {
        console.log(this.props.activeDriver)
        if (this.props.activeDriver === 'baseline') {
            return (
                <div key={driver.fields.content}>{driver.fields.content}</div>
            )
        }
        if (this.props.activeDriver === 'continuation') {
            return (
                <div key={driver.fields.continuation}>{driver.fields.continuation}</div>
            )
        }
        if (this.props.activeDriver === 'collapsed') {
            return (
                <div key={driver.fields.collapsed}>{driver.fields.collapsed}</div>
            )
        }
        if (this.props.activeDriver === 'newEq') {
            return (
                <div key={driver.fields.newEq}>{driver.fields.newEq}</div>
            )
        }
        if (this.props.activeDriver === 'transformative') {
            return (
                <div key={driver.fields.transformative}>{driver.fields.transformative}</div>
            )
        }
    }

    render () {
        const driver = this.props.drivers.filter(driver => driver.fields.number == this.props.activeDriverNumber).map((driver) => {
                console.log(driver)
                return (

                    <div className="driver-outer" key={driver.id}>
                        <div className={driver.fields.slug + " driver-wrapper"}>
                            {/* <div className="driver-overlay-wrapper"></div> */}
                            <div className="section-content">
                                <div className="article-back driver-back"><Link to="/research">&larr; Research</Link></div>
                                <div>
                                    <div className="driver-name">{driver.fields.name}</div>
                                </div>
                                <div className="driver-content">
                                    <ReactCSSTransitionGroup
                                        transitionName="driver-content-animation"
                                        transitionEnterTimeout={500}
                                        transitionLeaveTimeout={0}>
                                        {this.renderCorrectContent(driver)}
                                    </ReactCSSTransitionGroup>
                                </div>
                            </div>
                        </div>
                        {/* {this.renderCorrectContent.bind(this)} */}
                    </div>
                )
            }
        );

        return (
              <div className="drivers-wrapper">
                  <ReactCSSTransitionGroup
                      transitionName="example"
                      transitionEnterTimeout={500}
                      transitionLeaveTimeout={0}>
                      {driver}
                  </ReactCSSTransitionGroup>
              </div>
        )
    }
}


export default Drivers
